* {
  margin: 0;
  padding: 0;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 16px;
  font-family: 'Mallory';
}

a {
  color: none;
  text-decoration: none;
}

a:focus {
  color: none;
  text-decoration: none;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

#root {
  height: inherit;
}

amplify-sign-out {
  display: flex;
  width: 20%;
}

.ag-theme-alpine-dark .ag-header-group-cell-label {
  justify-content: center;
}

.ag-warning-row {
  background-color: #ffa726 !important;
}
